<template>
    <div class="single-header">
        <secondary-header title="GoBills" backRoute="default"></secondary-header>

        <main>
            <div class="container select-bills">
                <div class="row justify-content-center">
                    <div class="text-right mb-3 pt-2 col-md-10">
                        {{ lang('Credit Balance') }}: {{ money(wallet_details.wallet_balance) }}
                        <a href="#" @click.prevent="closeApp('gopay/reload/main')" class="text-info">
                            <font-awesome-icon icon="plus-circle" size="lg"></font-awesome-icon>
                        </a>
                    </div>
                </div>

                <div class="pt-5">
                    <div class="form-group row">
                        <!-- <label for="state_id" class="col-12 col-form-label">{{ lang('State') }}</label> -->
                        <div class="col-12">
                            <select class="custom-select" name="state_id" v-model="inputs.state_id" :disabled="state.loading_selection">
                                <option selected value="">{{ lang('-- Select State --') }}</option>
                                <option v-for="state in state_selections" :key="state.id" :value="state.id">{{ state.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <select class="custom-select" name="city_id" v-model="inputs.city_id" :disabled="state.loading_selection">
                                <option selected value="">{{ lang('-- Select City --') }}</option>
                                <option v-for="city in city_selections" :key="city.id" :value="city.id">{{ city.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <select class="custom-select" name="branch_id" v-model="inputs.branch_id" :disabled="state.loading_selection">
                                <option selected value="">{{ lang('-- Select Property --') }}</option>
                                <option v-for="branch in branch_selections" :key="branch.id" :value="branch.id">{{ branch.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <select class="custom-select" name="location_id" v-model="inputs.location_id" :disabled="state.loading_selection">
                                <option selected value="">{{ lang('-- Select Location --') }}</option>
                                <option v-for="location in location_selections" :key="location.id" :value="location.id">{{ location.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <float-bottom>
                <a href="#" @click.prevent="searchNow()">
                    <button type="button" class="btn btn-primary btn-block" :disabled="inputs.location_id == ''">{{ lang('Search') }}</button>
                </a>
            </float-bottom>
        </main>
    </div>
</template>

<script>
export default {
    data() {
        return {
            wallet_details: {},
            selections: {},
            inputs: {
                state_id: '',
                city_id: '',
                branch_id: '',
                location_id: '',
            },
        }
    },

    watch: {
        'inputs.state_id': function(newVal) {
            if(this.city_selections.find(x => x.id == this.inputs.city_id) == undefined) {
                this.inputs.city_id = ''
            }
            if(this.branch_selections.find(x => x.id == this.inputs.branch_id) == undefined) {
                this.inputs.branch_id = ''
            }
            if(this.location_selections.find(x => x.id == this.inputs.location_id) == undefined) {
                this.inputs.location_id = ''
            }
        },

        'inputs.city_id': function() {
            if(this.branch_selections.find(x => x.id == this.inputs.branch_id) == undefined) {
                this.inputs.branch_id = ''
            }
            if(this.location_selections.find(x => x.id == this.inputs.location_id) == undefined) {
                this.inputs.location_id = ''
            }
        },

        'inputs.branch_id': function() {
            if(this.location_selections.find(x => x.id == this.inputs.location_id) == undefined) {
                this.inputs.location_id = ''
            }
        },
    },

    computed: {
        state_selections: function() {
            return this.selections.states ? this.selections.states : []
        },

        city_selections: function() {
            if(this.inputs.state_id) {
                return this.selections.cities.filter(x => x.state_id == this.inputs.state_id)
            }
            return this.selections.cities
        },

        branch_selections: function() {
            if(this.inputs.city_id) {
                return this.selections.branches.filter(x => x.city_id == this.inputs.city_id)
            }
            else if(this.inputs.state_id) {
                return this.selections.branches.filter(x => _.map(this.city_selections, 'id').includes(x.city_id))
            }
            return this.selections.branches
        },

        location_selections: function() {
            if(this.inputs.branch_id) {
                return this.selections.locations.filter(x => x.branch_id == this.inputs.branch_id)
            }
            else if(this.inputs.city_id || this.inputs.state_id) {
                return this.selections.locations.filter(x => _.map(this.branch_selections, 'id').includes(x.branch_id))
            }
            return this.selections.locations;
        },
    },

    methods: {
        getCustomerWalletDetails() {
            this.$set(this.state, 'loading_wallet', true)
            axios.get(this.GLOBAL.API_BASE_URL +"customer/wallet-details")
                .then((response) => {
                    this.wallet_details = response.data;
                })
                .finally(() => this.$set(this.state, 'loading_wallet', false));
        },

        getReportSelection() {
            this.$set(this.state, 'loading_selection', true)
            axios.get(this.GLOBAL.API_BASE_URL +"report/selection")
                .then((response) => {
                    this.selections = response.data;
                })
                .finally(() => this.$set(this.state, 'loading_selection', false));
        },

        searchNow() {
            this.$router.push({ name:'report.result', params: {filters: this.inputs} })
        },
    },

    created() {
        this.getCustomerWalletDetails()
        this.getReportSelection()
    },

    mounted() {
        const header_height = $('.header-content').height()
        if(header_height > 0) {
            $('.select-bills .content').css('margin-top', header_height+'px');
        }
    },
}
</script>
